import React, { useState } from 'react';

const NumberDropdown = ({selectedNumber,setSelectedNumber}) => {

  const numbers = [];
  for (let i = 1; i <= 20; i++) {
    numbers.push(i);
  }
  console.log(selectedNumber);
  const handleSelectChange = (event) => {
    setSelectedNumber(event.target.value); // Update selected number when dropdown value changes
    console.log(selectedNumber);
  };

  return (
   
    <div className="dropdown-container">
      <select className="dropdown" value={selectedNumber} onChange={handleSelectChange}>

        {numbers.map((number, index) => (
          <option key={index} value={number}>
            {number}
          </option>
        ))}
      </select>
      {/* <span className='price'> 0.01 eth</span> */}
    </div>
 
  );
};

export default NumberDropdown;
