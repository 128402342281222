import React from "react";
import image1 from "./assets/page1/112.png"; // Import your image files
import image2 from "./assets/page1/113.png";
import image3 from "./assets/page1/114.png";
import image4 from "./assets/page1/115.png";
const ImageContainer = () => {
  return (
    <div className="icontainer">
      <img className="image" src={image1} alt="Image 1" />
      <img className="image" src={image2} alt="Image 2" />
      <img className="image" src={image3} alt="Image 3" />
      <img className="image" src={image4} alt="Image 4" />
    </div>
  );
};

export default ImageContainer;
