import React from 'react';

const Button = ({ onClick, children,connect }) => {
  return (
    <button className={`button ${connect ? '' : 'active'}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
