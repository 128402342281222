// App.js

import React, { useState, useEffect } from "react";
import "./App.css"; // Import your CSS file containing the styles
import Image1 from "./assets/page1/Vector1.png"; // Import your images
import twitter from "./assets/page1/twitter.PNG"; // Import your images
import Button from "./Button";
import NumberDropdown from "./Dropdown";
import ImageContainer from "./ImageContainer";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  checkSupply,
} from "./utils/interact";

import jsonData from "./Final.json";

//MERKLE



const App = () => {
  const [address, setAddress] = useState();

  let addr = "";
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("Connect");
  const [connect, setConnect] = useState(true);
  const [walletError, setWalletError] = useState(false);
  const [inbal, setInbal] = useState(true);
  const [wl, setWl] = useState(true);
  const [supply,setSupply] = useState(0);
  const [supplyStatus,setSupplyStatus] = useState(false);

  if(!connect){
    checkSupply().then((data) => {
      if(data.success!=false){

        const intValue = Number(data);
        const nftsleft = 10021- intValue;
        setSupply(nftsleft);
        
        console.log("Setsupply : "+data+typeof(data));
        
        setSupplyStatus(true)
      }
    })
  }




  const handleConnect = async () => {

    alert("SORRY,TOO LATE MFER")
    // connectWallet()
    //   .then((msg) => {
    //     console.log(msg);
    //     if (msg.status != "Connected") {
    //       setWalletError(true);
    //     } else {
    //       console.log(msg);
    //       if (msg.address != "" && msg.status == "Connected") {
    //         setConnect(false);
    //       }

    //       setStatus(msg.status);
    //       console.log(msg);
    //       let addressStr = String(msg.address);
    //       let tempadd = addressStr;
    //       console.log(tempadd);
    //       addr = tempadd
    //       setAddress(tempadd);
    //       setTimeout(() => {
    //         // Code to execute after waiting
    //         console.log("This code will run after waiting for a few seconds.");
    //       }, 5000); // Wait for 3000 milliseconds (3 seconds)
          
    //       console.log(addr);
    //     }
    //   })
  };
  const handleTwitterClick = () => {
    window.location.href = "https://twitter.com"; // Redirect to Twitter page
  };

  const [selectedNumber, setSelectedNumber] = useState(1); 

  return (
    <div className="container">
      <div className="section">
        <img
          onClick={handleTwitterClick}
          className="twitter"
          src={twitter}
        ></img>
        <img className="header" src={Image1}></img>
        <Button onClick={handleConnect} connect={connect}>{status}</Button>
      </div>
      <img className="header2" src={Image1}></img>
      <div className="mint">
        <NumberDropdown
          selectedNumber={selectedNumber}
          setSelectedNumber={setSelectedNumber}
          />
        
          <Button
          connect={true}
            onClick={() => {
              console.log("Clicked");
              console.log(selectedNumber);
              alert("SORRY,TOO LATE MFER")
              // if(connect){
              //   alert("Please connect wallet")
              // }
              // mintNFT(selectedNumber,address).then((msg) => {
              //   console.log(msg);
              //   console.log(msg.success);
              //   if (
              //     !msg.success &&
              //     msg.status !=
              //     "😥 Something went wrong: Returned error: MetaMask Tx Signature: User denied transaction signature."
              //   ) {
              //     if(!connect){    setInbal(false);
              //       setTimeout(() => {
              //         // Set inbal back to true after 5 seconds
              //         setInbal(true);
              //       }, 3000);}
              //     }
              //   });
            }}
            className="bg-[#D85F5D] text-lg hover:bg-[#26eeda] text-white font-bold py-4 px-14 rounded-full border-4"
          >
            {inbal ? "MINT" : "INSUFFICIENT BAL"}
          </Button>
      </div>
      <div className="price">
        <span className="texxts">
            {supplyStatus ? <span className="supply">{supply} Pacmfers left</span>:<span></span>}
          {" "}
          <span style={{ color: "yellow" }}>
            MINT PRICE: {(selectedNumber * 0.0003).toFixed(4)} eth / 1$
          </span>{" "}
          {walletError && (
            <p style={{ color: "red" }}>
              Check if you are on the right network mferr!!!
            </p>
          )}
        </span>
      </div>
      <div>
        <ImageContainer />
      </div>
    </div>
  );
};

export default App;
